import React from 'react';
import Layout from '../components/layout';
import Box, { BoxParagraph, BoxTitle } from '../elements/box';
import Card, { CardImage } from '../elements/card';

const Temoignages = () => (
    <Layout>
        <Box>
            <BoxTitle title="Témoignages de Lanzarote" />
            <div className="columns">
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzarote4" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column">
                    <Card height="">
                        <CardImage src="reflexions" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzarote2" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column">
                    <Card height="">
                        <CardImage src="gestalt" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
            </div>
        </Box>
        <Box background="primary-light">
            <BoxTitle title="Séjour Mai 2022" />
            <div className="columns">
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_1" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_2" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
            </div>
            <content className="centered-content">
                <BoxParagraph>
                    <p><em>Ce stage a été pour moi  chaque matin une célébration pour la vie - la Liberté d’être, de mouvements, de pensées-. Tout comme la Nature volcanique de Lanzarote nous murmure combien la vie est précieuse et délicieuse entourée de sœurs si vibrantes et accueillantes. Merci Anne et Béatrice, les muses qui s’amusent.</em> (NP)</p>
                </BoxParagraph>
            </content>
            <br />
            <div className="columns is-vcentered">
                <div className="column">
                    <content className="centered-content">
                        <BoxParagraph>
                            <p><em>Une semaine hors du temps, sans âge ni jugement. Lanzarote c’est se donner l’espace d’être pleinement soi, d’exprimer ses différences mais aussi ce qui nous rassemble ; partager nos doutes, notre vulnérabilité pour rencontrer la force du collectif, la soeurorité. Une expérience bouleversante, vibrante, puissante et pleine de joie à la croisée de 9 chemins.</em> (MA)</p>
                        </BoxParagraph>
                    </content>
                </div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_9" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column">
                    <content className="centered-content">
                        <BoxParagraph>
                            <p><em>Semaine d une incroyable intensité. Ateliers, rituels, groupe de paroles, rencontres qui donnent à réfléchir. La vie vaut la peine d être vécue même avec mes blessures. Merci Anne et Béatrice, merci au groupe de m avoir accepté comme je suis et avec mes silences.</em> (AP)</p>
                        </BoxParagraph>
                    </content>
                </div>
            </div>
            <br />
            <div className="columns">
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_6" alt="lanzarote" size="is-3by2"/>
                    </Card>
                </div>
                <div className="column is-1"></div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_7" alt="lanzarote" size="is-3by2"/>
                    </Card>
                </div>
            </div>
            <content className="centered-content">
                <BoxParagraph>
                    <p><em>Lanzarote a été pour moi la découverte de la soeurorité, de l’acceptation de soi et de l’autre, dans sa globalité vraie, sans jugement ni crainte. Un espace de liberté d’être pleinement soi et d’être aimée pour ce que je suis et d’aimer, tout simplement. Ce fut un séjour débordant d’amour et de bienveillance, d’empathie et de partage d’émotions fortes et intenses ! Un séjour dense et vibrant. Mes 5 sens ont été réveillés et redécouverts. Merci à Anne et Béatrice, à vous toutes que j’ai découvertes si belles dans votre vérité d’être vous-mêmes.</em> (AM)</p>
                </BoxParagraph>
            </content>
            <br />
            <div className="columns is-vcentered">
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_8" alt="lanzarote" size="is-3by2"/>
                    </Card>
                </div>
                <div className="column">
                    <content className="centered-content">
                        <BoxParagraph>
                            <p><em>Cette retraite fut la découverte d'une île,  Lanzarote, mais aussi d'un cocoon, d'un groupe de femmes singulières, sensibles et puissantes, d'altérité et de solidarité. Ce fut l'occasion de faire resurgir des émotions parfois un peu trop enfouies et de se sentir entière et libre d'être ce que je suis. Ce fut une semaine dense, vibrante et puissante, accompagnée par Anne et Béatrice,  et toutes les soeurs présentes.</em> (GC)</p>
                        </BoxParagraph>
                    </content>
                </div>
            </div>
            <br />
            <div className="columns is-vcentered">
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_4" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column">
                    <content className="centered-content">
                        <BoxParagraph>
                            <p><em>La retraite à Lanzarote, île d’une énergie puissante, m’a donné la force d’oser vivre. Je me suis sentie soutenue et en sécurité pour partager mes peurs, mes fragilités, mes angoisses. Les émotions sont accueillies avec bienveillance. Accompagnée par Anne, Béatrice et toutes les femmes du groupe, j’ai pu renaître. La force du collectif est puissante. Ce séjour, c’est un moment de ma vie qui a déjà changé toute ma vie.</em> (CV)</p>
                        </BoxParagraph>
                    </content>
                </div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_5" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
            </div>
            <br />
            <content className="centered-content">
                <BoxParagraph>
                    <p><em>Ce voyage à Lanzarote est le voyage initiatique de ma vie. Je sais que rien ne sera plus comme avant. J'ai été initiée à la soeurorité, à la puissance du collectif quand il est juste, doux et guérisseur. J'ai été initiée à de nouvelles sensibilités, à de nouvelles sensualités et à des liens indéfectibles. Je porte cette empreinte que je veux indélébile, d'un nouveau corps, d'un coeur plus fort et d'une essence rencontrée.</em></p>
                    <p><em>Ma gratitude à toutes.</em> (IA)</p>
                </BoxParagraph>
            </content>
            <br />
            <div className="columns is-vcentered">
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_3" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column is-1"></div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_10" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
                <div className="column is-1"></div>
                <div className="column">
                    <Card height="">
                        <CardImage src="lanzaroteMai22_11" alt="lanzarote" size="is-4by3"/>
                    </Card>
                </div>
            </div>
        </Box>
    </Layout>
)

export default Temoignages;